import { t } from "i18next";
import { NextRouter } from "next/router";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { ApiError } from "../../api/ApiHelper";
import { setNewEvent } from "../../redux/event/event.actions";
import { logOut } from "../../redux/session/session.actions";
import { openSnackbar } from "../../redux/snackbar/snackbar.actions";
import { SnackbarTypeEnum } from "../../redux/snackbar/snackbar.reducer";
import BugsnagHelper from "../../utils/BugsnagHelper";
import { ROUTES } from "../../utils/consts";
import { useTypedSelector } from "../../utils/use-typed-selector";
import { Repository } from "./Repository";
import { useMainAppState } from "./State";

export interface Handler {
    isUserInLoginPage: boolean,
    hasOptInOutCookieValue: boolean,
    onUpdateOptInOutCookie(value: string): void

    venueReferrerCode: string
    openVenueReferrerConfirmDialog: boolean
    handleConfirmVenueReferrerConfirmDialog(): void
    handleCancelVenueReferrerConfirmDialog(): void
}

export const useMainAppHandler = (router: NextRouter, repository: Repository): Handler => {

    const cartState = useTypedSelector((state) => state.eventReducer.currentEvent?.cart);
    const state = useMainAppState()
    const dispatch = useDispatch()


    // ________________________________________________________________________________________________________LIFECYCLE
    useEffect(() => {
        repository.getUserInfo().catch(error => {
            // this logout is for the case where the KeratinLogin is timed out, so the user is no more logged in,
            // therefore reset the redux state of the session by dispatching a simple logout action
            dispatch(logOut())
        })
    }, []);

    useEffect(() => {
        if(!router.isReady) return;

        state.setIsUserInLoginPage(router.route === ROUTES.ACCOUNT.LOGIN);

        if(router.query.ref && state.venueReferrerCode !== router.query.ref) {
            checkVenueReferrerCode(router.query.ref as string)
        }

    }, [router.isReady, router.route]);


    useEffect(() => {
        state.setHasOptInOutCookieValue(state.cookies.OptInOut)
    }, [state.cookies])


    // ___________________________________________________________________________________________________________CLICKS
    const onUpdateOptInOutCookie = (value: string) => {
        state.setCookie('OptInOut', value, { path: '/' });
    }

    const handleConfirmVenueReferrerConfirmDialog = async () => {
        try {
            if(state.venueReferrerCode){
                const {cart, spData} = await repository.createCartFromVenueCode(state.venueReferrerCode)

                dispatch(setNewEvent(cart, null, spData.spatialToken, spData.defaultMarketRedirectionUrl))
                // Redirect to the default catalog page
                router.push(spData.defaultMarketRedirectionUrl)
                handleCancelVenueReferrerConfirmDialog()
            }
        }catch (error) {
            if(error instanceof ApiError && error.statusCode === 404){
                dispatch(openSnackbar(SnackbarTypeEnum.ERROR, t("BANKROLL.CodeInvalid")))
                handleCancelVenueReferrerConfirmDialog()
            }
            BugsnagHelper.notify(new Error(`[useMainAppHandler]#handleConfirmVenueReferrerConfirmDialog: error when confirming: ${state.venueReferrerCode} | ${JSON.stringify(error)}`))
        }
    }

    const handleCancelVenueReferrerConfirmDialog = () => {
        state.handleCancelVenueReferrerConfirmDialog()
    }


    // __________________________________________________________________________________________________________PRIVATE
    const checkVenueReferrerCode = async (venueReferrerCode: string|undefined) => {
        try{
            state.setVenueReferrerCode(venueReferrerCode ?? '')
            if(venueReferrerCode && venueReferrerCode.length > 0){
                if(!cartState){
                    // If the user doesn't have a current cart, create one and update the Redux state. All pages will react to it
                    const {cart, spData} = await repository.createCartFromVenueCode(venueReferrerCode)
                    dispatch(setNewEvent(cart, null, spData.spatialToken, spData.defaultMarketRedirectionUrl))
                }else if (cartState.venue_address.code !== venueReferrerCode){
                    // User already has a cart in its Redux state, only ask him if he wants to change venue if it's a different one
                    state.handleOpenVenueReferrerConfirmDialog()
                }
            }
        }catch (error) {
            BugsnagHelper.notify(new Error(`[useMainAppHandler]#checkVenueReferrerCode: error for code: ${venueReferrerCode} | ${JSON.stringify(error)}`))
        }
    }

    return {
        isUserInLoginPage: state.isUserInLoginPage,
        hasOptInOutCookieValue: state.hasOptInOutCookieValue,
        onUpdateOptInOutCookie,

        venueReferrerCode: state.venueReferrerCode,
        openVenueReferrerConfirmDialog: state.openVenueReferrerConfirmDialog,
        handleConfirmVenueReferrerConfirmDialog,
        handleCancelVenueReferrerConfirmDialog

    };
}