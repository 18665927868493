import MeApi from "../../api/me/MeApi";
import {Card, CssBaseline, Grid, Typography} from "@mui/material";
import Button from "@mui/material/Button";
import 'i18n';
import {t} from 'i18next';
import {NextComponentType, NextPageContext} from "next";
import {useRouter} from "next/router";
import {useTypedSelector} from "../../utils/use-typed-selector";
import {CustomSnackbar} from "../../ui/common/CustomSnackbar";
import {Footer} from "../../ui/footer/Footer";
import {Outfit} from '@next/font/google';
import {useMainAppHandler} from "./Handler";
import {Repository} from "./Repository";
import VenueApi from "../../api/venues/VenueApi";
import {VenueReferrerConfirmationDialog} from "../../ui/main-app/VenueReferrerConfirmationDialog";
import CartApi from "../../api/carts/CartApi";

global.XMLHttpRequest = require('xhr2');

const outfit_font = Outfit({ subsets: ['latin'] })

interface MainAppProps {
    Component: NextComponentType<NextPageContext, any, any>,
    pageProps: {}
}

export const MainAppView = ({Component, pageProps}: MainAppProps) => {

    const router = useRouter();
    const handler = useMainAppHandler(router, new Repository(new MeApi(), new CartApi(), new VenueApi()))
    const appTheme = useTypedSelector((state) => state.themeReducer.appTheme);

    return <>
        <div className={`theme--${appTheme} ${outfit_font.className}`}>
            <CssBaseline/>

            <main>
                <Component{...pageProps}/>
            </main>
            { !handler.isUserInLoginPage && <Footer/> }

                {
                    !handler.hasOptInOutCookieValue &&
                    
                    <Card className='cookies-card' elevation={1}>
                        <Typography className='c-text'>
                            {t("HOME.Cookies")}
                        </Typography>
                        <Grid container className='c-container'>

                            <Button variant="text"
                                    className='sedeo-button-text'
                                    onClick={() => { handler.onUpdateOptInOutCookie("no")}}
                            >
                                {t("BUTTONS.NoThanks")}
                            </Button>

                            <Button variant="contained"
                                className='sedeo-button-normal'
                                onClick={() => {
                                    handler.onUpdateOptInOutCookie("yes")
                                    if(window.gtag_update_consent) {
                                        window.gtag_update_consent()
                                    }
                                }}
                            >
                                {t("BUTTONS.Ok")}
                            </Button>

                        </Grid>
                    </Card>
                }
        </div>

        <CustomSnackbar/>
        <VenueReferrerConfirmationDialog
            venueCode={handler.venueReferrerCode}
            open={handler.openVenueReferrerConfirmDialog}
            onConfirm={handler.handleConfirmVenueReferrerConfirmDialog}
            onCancel={handler.handleCancelVenueReferrerConfirmDialog}
        />
    </>
}