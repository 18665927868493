import { Timestamp } from "google-protobuf/google/protobuf/timestamp_pb";
import { CatalogClient } from "../../../../proto_generated/catalog/CatalogServiceClientPb";
import { CatalogGetMsg, CatalogItemMsg, CatalogListMsg } from "../../../../proto_generated/catalog/catalog_pb";
import { GeolocationClient } from "../../../../proto_generated/geolocation/GeolocationServiceClientPb";
import { GetSpatialTokenMsg } from "../../../../proto_generated/geolocation/geolocation_pb";
import { PageRequestMsg } from "../../../../proto_generated/type/pager_pb";
import { CollectionProduct, ProductItemType, SingleProduct, SpatialDataResult, createProductType } from "../../../typing/types";
import ItemsOrderHelper from "../../../ui/market/ItemsOrderHelper";
import { CollectionHelper } from "../../CollectionHelper";
import RoutesHelper, { PRODUCT_TYPE } from "../../consts";
import MarketCollectionApiHelper from "../cms/MarketCollectionApiHelper";
import MarketItemAPIHelper from "../cms/MarketItemAPIHelper";
import LatLng = GetSpatialTokenMsg.LatLng;
import BugsnagHelper from "../../BugsnagHelper";

export default class ProductsAPIHelper {

    //#region private stuff of the class
    static #ENV_URL = process.env.NEXT_PUBLIC_GRPC_WEB_PROXY_URL!!;

    static #preparePageRequest = () => {
        let pageReq = new PageRequestMsg();
        pageReq.setSorting("uuid");
        pageReq.setOrder("ASC");
        pageReq.setRequested(0);
        pageReq.setSize(3000);
        return pageReq;
    }

    static #prepareCatalogListMsg = (categoryUuids: string[]) => {


        let catalogListMsg = new CatalogListMsg();
        catalogListMsg.setRequest(this.#preparePageRequest());
        if(categoryUuids.length > 0) {
            let filter = new CatalogListMsg.FilterMsg();
            filter.setCategoriesUuidList(categoryUuids);
            catalogListMsg.setFilter(filter);
        }

        return catalogListMsg;
    }

    static #prepareCatalogClient = () => {
        return new CatalogClient(this.#ENV_URL);
    }
    //#endregion
    /**
     *
     * This method returns a SingleProduct available for a spatialToken
     */
    static getSpatialToken = (lat: number, lng: number, range: number): Promise<string> => {

        // just for testing purposes
        let delivery = new Timestamp()
        delivery.fromDate(new Date(2024, 7, 22, 14, 0, 0))
        let retrieval = new Timestamp()
        retrieval.fromDate(new Date(2024, 7, 24, 18, 0, 0))

        return new Promise((resolve, reject) => {
            let getSpatialTokenMsg = new GetSpatialTokenMsg()
            let latLng = new LatLng()
            latLng.setLatitude(lat)
            latLng.setLongitude(lng)
            getSpatialTokenMsg.setPosition(latLng)
            getSpatialTokenMsg.setStart(delivery)
            getSpatialTokenMsg.setEnd(retrieval)
            getSpatialTokenMsg.setRange(range) // Not used for the moment
            let client = new GeolocationClient(process.env.NEXT_PUBLIC_GRPC_WEB_PROXY_URL!!)
            client.getSpatialToken(getSpatialTokenMsg, {}, (err, res) => {
                if (res) {
                    resolve(res.getSpatialToken())
                } else {
                    reject(err)
                }
            })
        })
    }

    /**
     *
     * @param itemUuid
     * @param spatialToken if undefined it will return the default catalogItem
     */
    static getCatalogItem = async (itemUuid: string, spatialToken: string|undefined): Promise<CatalogItemMsg.AsObject> => {
        return new Promise((resolve, reject) => {
            let catalogGetMsg = new CatalogGetMsg()
            catalogGetMsg.setItemUuid(itemUuid)
            if(spatialToken){
                catalogGetMsg.setSpatialToken(spatialToken)
                this.#prepareCatalogClient().getByIntersect(catalogGetMsg, {}, (err, res) => {
                    if(res){
                        resolve(res.getItem()!.toObject())
                    }else{

                        reject(err)
                    }
                })
            } else{
                this.#prepareCatalogClient().get(catalogGetMsg, {}, (err, res) => {
                    if(res){
                        resolve(res.getItem()!.toObject())
                    }else{
                        reject(err)
                    }
                })
            }
        })
    }
    /**
     *
     * @param categoryUuids pass an empty array to fetch all items
     * @param spatialToken
     */
    static listCatalog = (categoryUuids: string[], spatialToken: string|undefined): Promise<CatalogItemMsg[]> => {
        return new Promise((resolve, reject) => {
            if(spatialToken){
                let catalogListMsg = this.#prepareCatalogListMsg(categoryUuids)
                catalogListMsg.setSpatialToken(spatialToken)
                this.#prepareCatalogClient().listByIntersect(catalogListMsg, {}, (err, res) => {
                    if(res){
                        resolve(res.getItemsList())
                    }else{
                        reject(err)
                    }
                })
            }else{
                this.#prepareCatalogClient().list(this.#prepareCatalogListMsg(categoryUuids), {}, (err, res) => {
                    if(res){
                        resolve(res.getItemsList())
                    }else{
                        reject(err)
                    }
                })
            }
        })
    }

    /**
     *
     * This method returns a SingleProduct available for a spatialToken
     * @param spatialToken
     */
    static getAvailableProduct = (spatialToken: string): Promise<SingleProduct> => {
        return new Promise((resolve, reject) => {
            let catalogListMsg = new CatalogListMsg();
            let pageReq = new PageRequestMsg();
            pageReq.setSorting("uuid");
            pageReq.setOrder("ASC");
            pageReq.setRequested(0);
            pageReq.setSize(2);
            catalogListMsg.setRequest(pageReq);
            catalogListMsg.setSpatialToken(spatialToken)
            this.#prepareCatalogClient().listByIntersect(catalogListMsg, {}, (err, res) => {
                if(res){
                    if(res.getItemsList().length > 0){
                        let catalogItem = res.getItemsList()[0]
                        MarketItemAPIHelper.getMarketItemByItemUuid(catalogItem.getUuid()).then(marketItem => {
                            resolve(createProductType(catalogItem.toObject(), marketItem))
                        }).catch(err => {
                            reject(err)
                        })
                    }else{
                        reject("No item found")
                    }
                }else{
                    reject(err)
                }
            })
        })
    }

    // to get the products list of a sub-category
    static getSubCategoryProductsList = async (itemCategoryUuid: string, SPToken: string|undefined, itemOrders: {itemUuid: string, order: number}[]): Promise<ProductItemType[]> => {
        return await new Promise((resolve, reject) => {
            this.listCatalog([itemCategoryUuid], SPToken).then( list => {
                this.fetchProductItemFromCatalogItems(list, itemOrders).then(productItems => {
                    resolve(productItems);
                }).catch(err => {
                    reject(err)
                })
            }).catch(err => {
                console.error(err)
                reject(`getSubCategoryProductsList. rejected ${err}`)
            })
        })
    }

    static fetchProductItemFromCatalogItems = async (catalogItems: CatalogItemMsg[], itemOrders: {itemUuid: string, order: number}[]): Promise<ProductItemType[]> => {
        return await new Promise((resolve, reject) => {
            if(catalogItems.length === 0){
                resolve([])
            }else {

                let data = catalogItems.sort((a, b) =>
                    ItemsOrderHelper.getItemsOrder(a.getCategoriesList()[0].getName(), a.getName()) < ItemsOrderHelper.getItemsOrder(b.getCategoriesList()[0].getName(), b.getName()) ? - 1 : 1)
                    .map((item) => item.toObject())

                MarketItemAPIHelper.getMarketItemsByOwners(catalogItems.map(item => item.getUuid())).then(async marketItems => {
                    const products: ProductItemType[] = [];
                    if(marketItems?.length > 0){
                        for(const catalogItem of catalogItems){
                            let marketItem = marketItems.find(marketItem => marketItem.catalogItemUuid === catalogItem.getUuid());
                            if(!marketItem){
                                // This should never happens as the Admin doesn't allow to create an Item without its MarketItem.
                                BugsnagHelper.captureMessage("getCategoryProductsList could not find market item for : "+catalogItem.getName())
                            }
                            else{
                                let product = data.find(product => product.uuid === marketItem?.catalogItemUuid)
                                if(product){

                                    const productCollections = CollectionHelper.filterByCollectionSubCategories(product.categoriesList)

                                    if(productCollections?.length > 0){

                                        for(const pc of productCollections){
                                            const _collection = products.find(coll => coll.type === PRODUCT_TYPE.COLLECTION && coll.marketCollection.content.itemCategoryUuid === pc.uuid)
                                            if(_collection?.type === PRODUCT_TYPE.COLLECTION) {

                                                // found the collection in the list, just add the product to its items list
                                                _collection.items.push(createProductType(product, marketItem))
                                            }
                                            // otherwise fetch the collection from the API, add the product to its items list, and add the collection to the collections list 👌
                                            else{
                                                const marketCollection = await MarketCollectionApiHelper.getMarketCollectionByItemCategoryUuid(pc.uuid);

                                                if(marketCollection){
                                                    const newCollection: CollectionProduct = {
                                                        type: PRODUCT_TYPE.COLLECTION,
                                                        marketCollection: marketCollection,
                                                        items: [createProductType(product, marketItem)]
                                                    }
                                                    products.push(newCollection)
                                                }
                                                else{
                                                    // if, for somehow, fetching the collection has crashed, just add the product to the collections list as it is, to not to miss it.
                                                    products.push(createProductType(product, marketItem))
                                                    console.error('[COLLECTIONS] error this collection :', pc.uuid)
                                                }
                                            }
                                        }
                                    }
                                    // otherwise just add the product as it is to the products/collections list
                                    else{
                                        products.push(createProductType(product, marketItem))
                                    }
                                }
                                else{
                                    reject(`error in getSubCategoryProductsList: could not find product corresponding to market item with catalogItemUuid ${marketItem?.catalogItemUuid}`)
                                }
                            }
                        }
                    }
                    if(itemOrders.length > 0){
                        resolve(ItemsOrderHelper.sortItems(itemOrders, products))
                    }else{
                        resolve(products);
                    }
                }).catch(err => {
                    reject(err)
                })

            }

        })
    }

    /**
     *
     * @param SPToken if undefined it will return the whole catalog
     */
    static getAllProductsList = async (SPToken: string|undefined): Promise<SingleProduct[]> => {
        return await new Promise((resolve, reject) => {
            this.listCatalog([], SPToken).then(catalogItems => {
                const products: SingleProduct[] = [];
                if(catalogItems.length === 0){
                    resolve([])
                }else {

                    let data = catalogItems.sort((a, b) =>
                        ItemsOrderHelper.getItemsOrder(a.getCategoriesList()[0].getName(), a.getName()) < ItemsOrderHelper.getItemsOrder(b.getCategoriesList()[0].getName(), b.getName()) ? - 1 : 1)
                        .map((item) => item.toObject())

                    MarketItemAPIHelper.getMarketItemsByOwners(catalogItems.map(item => item.getUuid()), false).then(marketItems => {
                        if(marketItems?.length > 0){
                            catalogItems.map(catalogItem => {
                                let marketItem = marketItems.find(marketItem => marketItem.catalogItemUuid === catalogItem.getUuid());
                                if(!marketItem){
                                    // This should never happens as the Admin doesn't allow to create an Item without its MarketItem.
                                    BugsnagHelper.captureMessage("getCategoryProductsList could not find market item for : "+catalogItem.getName())
                                }
                                else{
                                    let product = data.find(product => product.uuid === marketItem?.catalogItemUuid)
                                    if(product){
                                        products.push(createProductType(product, marketItem))
                                    }
                                    else{
                                        reject(`error in getSubCategoryProductsList: could not find product corresponding to market item with catalogItemUuid ${marketItem?.catalogItemUuid}`)
                                    }
                                }
                            })
                        }
                        resolve(products);
                    }).catch(err => {
                        reject(err)
                    })
                }
            }).catch(err => {
                console.error(err)
                reject(`getAllProductsList. rejected ${err}`)
            })
        })
    }


    static getSpatialData = async (lat: number, lng: number): Promise<SpatialDataResult> => {
        let spatialToken = await ProductsAPIHelper.getSpatialToken(lat, lng, 1000)

        let defaultMarketRedirectionUrl: string
        try{
            let availableProduct = await this.getAvailableProduct(spatialToken)
            defaultMarketRedirectionUrl = RoutesHelper.getSubCategoryRouteByPathName(availableProduct.marketItem.content.marketCategorySlug, availableProduct.marketItem.content.marketSubCategorySlug)
        }catch (e) {
            // No items were available. Redirect to a default page
            defaultMarketRedirectionUrl = RoutesHelper.getDefaultCatalogPage()
        }

        return {
            spatialToken: spatialToken,
            defaultMarketRedirectionUrl: defaultMarketRedirectionUrl
        }
    }

}


